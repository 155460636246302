<template>
  <div id="checkout">
    <div>
      <header class="thank-you-title bg-cl-secondary py35 pl20">
        <div class="container">
          <breadcrumbs
            :with-homepage="true"
            :routes="[]"
            :active-route="this.$t('Order confirmation')"
          />
          <h2 class="category-title">
            {{ $t('Order confirmation') }}
          </h2>
        </div>
      </header>
      <div class="thank-you-content align-justify py40 pl20">
        <div class="container" v-if="!isLoading">
          <div class="row">
            <div class="col-md-6 pl20 pr20">
              <h3 v-if="OnlineOnly">
                {{ $t('Order placed') }}
              </h3>
              <p v-if="OnlineOnly && paymentProcessed" v-html="this.$t('You have successfuly placed the order. You will receive order summary and confirmation via email.')" />
              <p class="cl-anyrent" v-if="OnlineOnly && !paymentProcessed" v-html="this.$t('Payment has failed, please contact us or follow instructions sent to you by email')" />
              <p v-if="OnlineOnly && lastOrderConfirmation.orderNumber" v-html="`${this.$t('The OrderNumber is')}: ${ lastOrderConfirmation.orderNumber }`" />
              <h4 v-if="OfflineOnly">
                {{ $t('You are offline') }}
              </h4>
              <p v-if="OfflineOnly && !isNotificationSupported">
                {{ $t('To finish the order just come back to our store while online. Your order will be sent to the server as soon as you come back here while online and then confirmed regarding the stock quantities of selected items') }}
              </p>
              <p v-if="OfflineOnly && isNotificationSupported && !isPermissionGranted">
                {{ $t("You can allow us to remind you about the order via push notification after coming back online. You'll only need to click on it to confirm.") }}
              </p>
              <p v-if="OfflineOnly && isNotificationSupported && !isPermissionGranted">
                {{ $t(`Or if you will stay on "Order confirmation" page, the order will be placed automatically without confirmation, once the internet connection will be back.`) }}
              </p>
              <p v-if="OfflineOnly && isNotificationSupported && isPermissionGranted">
                <strong>{{ $t('You will receive Push notification after coming back online. You can confirm the order by clicking on it') }}</strong>
              </p>
              <p class="mt33">
                <button-outline
                  class="button-width"
                  color="dark"
                  @click.native="$router.push('/')"
                >
                  {{ $t('Return to shopping') }}
                </button-outline>
              </p>
              <div id="thank-you-extensions" />
            </div>
            <div class="col-md-6 bg-cl-secondary thank-you-improvment pl30 pr30">
              <h3>
                {{ $t('What we can improve?') }}
              </h3>
              <p class="mb25">
                {{ $t('Your feedback is important for us. Let us know what we could improve.') }}
              </p>
              <form @submit.prevent="sendFeedback" v-if="!thankYou">
                <span v-if="!$v.feedback.required && submitted" class=" error">{{ $t('You can not send an empty text') }}</span>
                <base-textarea
                  class="mb25"
                  type="text"
                  name="body"
                  v-model="feedback"
                  :placeholder="$t('Type your opinion')"
                  :custom-style="{'max-height':`240px`}"
                  :autoheight="true"
                  :autofocus="false"
                />
                <button-outline
                  class="button-width"
                  color="dark"
                >
                  {{ $t('Give a feedback') }}
                </button-outline>
              </form>
              <span v-else>
                {{ $t('Thank you!') }}
              </span>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <!-- todo -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import VueOfflineMixin from 'vue-offline/mixin'
import { EmailForm } from '@vue-storefront/core/modules/mailer/components/EmailForm'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { MailerModule } from '@vue-storefront/core/modules/mailer'
import { OrderService } from '@vue-storefront/core/data-resolver'
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'OrderResult',
  mixins: [VueOfflineMixin, EmailForm],

  beforeCreate () {
    registerModule(MailerModule)
  },
  data () {
    return {
      feedback: '',
      thankYou: false,
      submitted: false,
      isCheckoutPage: true,
      lastOrderConfirmation: {
        orderNumber: 'unknown'
      },
      isLoading: true,
      // TODO Zpatky
      paymentProcessed: true
    }
  },
  computed: {
    isNotificationSupported () {
      if (isServer || !('Notification' in window)) return false
      return 'Notification' in window
    },
    isPermissionGranted () {
      if (isServer || !('Notification' in window)) return false
      return Notification.permission === 'granted'
    },
    checkoutPersonalEmailAddress () {
      return this.$store.state.checkout.personalDetails.emailAddress
    },
    mailerElements () {
      return config.mailer.contactAddress
    }
  },
  async created () {
    this.checkId()
  },
  methods: {
    async checkId () {
      if (this.$route.query.id) {
        const status = await OrderService.orderStatus(this.$route.query.id)

        if (status.code !== 200) {
          this.$router.push('/')
        } else {
          this.isLoading = false;
          if (status.result.paymentProcessed) {
            this.paymentProcessed = true;
          } else {
            this.paymentProcessed = false;
            this.$store.dispatch('notification/spawnNotification', {
              type: 'warning',
              message: this.$t('Payment has failed, please contact us or follow instructions sent to you by email'),
              action1: { label: this.$t('OK') }
            })
          }
          if (status.result.customerOrderId) {
            this.lastOrderConfirmation.orderNumber = status.result.customerOrderId
          } else {
            this.isLoading = true;
            this.$store.dispatch('notification/spawnNotification', {
              type: 'warning',
              message: this.$t('There was a problem with connection to the payment service and retieving the order number, try to refresh the page in a while, please.'),
              action1: { label: this.$t('OK') }
            })
          }
        }
      } else {
        // todo better place the check to the router directly
        // we do not want people to come here without an ID
        this.$router.push('/')
      }
    },
    requestNotificationPermission () {
      if (isServer) return false
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    },
    sendFeedback () {
      this.submitted = true;
      if (this.$v.feedback.$invalid) {
        return
      }
      this.sendEmail(
        {
          sourceAddress: this.checkoutPersonalEmailAddress,
          targetAddress: this.mailerElements,
          subject: this.$t('What we can improve?'),
          emailText: this.feedback
        },
        this.onSuccess,
        this.onFailure
      )
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      })
      if (this.mailerElements.sendConfirmation) {
        this.sendEmail(
          {
            sourceAddress: this.mailerElements,
            targetAddress: this.checkoutPersonalEmailAddress,
            subject: this.$t('Confirmation of receival'),
            emailText: this.$t(`Dear customer,\n\nWe have received your letter.\nThank you for your feedback!`),
            confirmation: true
          }
        )
      }
      this.thankYou = true;
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: this.$t('OK') }
      })
      this.thankYou = true;
    }
  },
  destroyed () {
    this.$store.dispatch('checkout/setThankYouPage', false)
  },
  components: {
    BaseTextarea,
    Breadcrumbs,
    ButtonOutline
  },
  validations: {
    feedback: {
      required,
      minLength: minLength(2)
    }
  }
}
</script>

<style lang="scss">
  .thank-you-content {
    padding-left: 0;

    p {
      line-height: 25px
    }

    @media (min-width: 64em) {
      h4 {
        font-size: 24px;
      }
    }
  }
  .mt33{
    margin-top:33px
  }
  .button-width {
    width: 240px;
  }
  .thank-you-improvment {
    padding: 0 20px 15px;

    @media (min-width: 64em) {
      padding: 0 40px 10px;
    }

    textarea {
      min-height: 100px;
    }
  }
  span.error {
    color:red;
    font-size: 13px;
    position: relative;
    top: -8px;
  }
</style>
